import React from "react";
import useCustomFecthQuery from "../../../Hooks/useCustomFecthQuery";
import CreateCompanyProfile from "./CreateCompanyProfile";
import BoothCompany from "./BoothCompany";

const CompanyComponent = ({ token, boothID, boothDetails }) => {
  const {
    data: getCompanyDetails,
    error: errorGetCompanyDetails,
    isLoading: isLoadingGetCompanyDetails,
  } = useCustomFecthQuery(
    ["companyDetails", boothID],
    `${process.env.REACT_APP_API}company-profile/booth/${boothID}`,
    token,
    { enabled: !!boothID }
  );
  if (isLoadingGetCompanyDetails) {
    return <div>Loading ......</div>;
  }

  // console.log(
  //   "company details....",
  //   JSON.stringify(getCompanyDetails, null, 2)
  // );
  return (
    <>
      {getCompanyDetails?.data?.id ? (
        <BoothCompany
          token={token}
          boothID={boothID}
          getCompanyDetails={getCompanyDetails?.data}
        />
      ) : (
        <CreateCompanyProfile token={token} boothID={boothID} />
      )}
    </>
  );
};

export default CompanyComponent;
