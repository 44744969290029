import React from "react";
import SimpleBar from "simplebar-react";
import { FaEdit } from "react-icons/fa";
import ButtonIcon from "../../Buttons/ButtonIcon";
import { RiGlobalFill } from "react-icons/ri";
import { MdEmail } from "react-icons/md";
import { FaPhoneSquareAlt } from "react-icons/fa";
import { IoLogoLinkedin } from "react-icons/io5";
import { FaFacebook } from "react-icons/fa6";
import { BiLogoInstagramAlt } from "react-icons/bi";
// import boothTwo from "../../../assets/images/booths/2.png";
// import boothThree from "../../../assets/images/booths/3.png";
// import boothFour from "../../../assets/images/booths/4.png";

const BoothCompany = ({ token, boothID, getCompanyDetails }) => {
  // console.log("boothDetails.....", JSON.stringify(boothDetails, null, 2));
  // console.log("boothDetails.....", JSON.stringify(boothDetails, null, 2));

  const openLink = (link) => {
    const newLink = window.open(`${link}`, "_blank", "noopener,noreferrer");
    if (newLink) newLink.opener = null;
  };
  return (
    <div className="mt-3 mb-5 d-flex justify-content-center">
      <div
        style={{ width: "100%", height: 300 }}
        className="border border-primary rounded-4 p-3"
      >
        <div className="w-100 mb-2 d-flex justify-content-between">
          <div
            className="h5"
            style={{
              width: 250,
            }}
          >
            Company details
          </div>
          <div className="">
            <ButtonIcon
              Title="Edit Company"
              BackgroundColor="#f68a20"
              ColorText="#fff"
              BorderColor="#f68a20"
              borderRadius={20}
              handleOnclick={() => {}}
              Icon={<FaEdit size={15} color="#fff" />}
              pending={false}
            />
          </div>
        </div>
        <div
          className="w-100 d-flex gap-3"
          style={{
            height: 200,
          }}
        >
          <div
            style={{
              width: 300,
              height: 150,
              borderRadius: 10,
            }}
          >
            <img
              src={getCompanyDetails?.companyLogo}
              alt="booth-img"
              className="w-100 h-100"
              style={{ objectFit: "cover", borderRadius: 10 }}
            />
          </div>
          <div
            style={{
              width: "100%",
              height: 250,
              borderRadius: 10,
            }}
          >
            <div className="w-100 px-2">
              <div className="w-100">
                <p style={{ fontSize: 16, fontWeight: "600", color: "black" }}>
                  {getCompanyDetails?.companyName}
                </p>
                <SimpleBar
                  style={{
                    width: "100%",
                    height: 110,
                    borderRadius: 10,
                  }}
                >
                  <p>{getCompanyDetails?.companyDescription}</p>
                </SimpleBar>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center gap-2">
            {getCompanyDetails?.companyWebsite ? (
              <div
                className="d-flex align-items-center gap-1 fw-bold"
                onClick={() => openLink(getCompanyDetails?.companyWebsite)}
              >
                <RiGlobalFill size={14} />
                <span
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                >
                  {getCompanyDetails?.companyWebsite}
                </span>
              </div>
            ) : null}

            {getCompanyDetails?.companyEmail ? (
              <div className="d-flex align-items-center gap-1 fw-bold">
                <MdEmail size={16} />
                <span>{getCompanyDetails?.companyEmail}</span>
              </div>
            ) : null}

            {getCompanyDetails?.companyPhone ? (
              <div className="d-flex align-items-center gap-1 fw-bold">
                <FaPhoneSquareAlt size={14} />
                <span>{getCompanyDetails?.companyPhone}</span>
              </div>
            ) : null}
          </div>
          <div className="d-flex align-items-center gap-2">
            Follow us:
            {getCompanyDetails?.companyLinkedIn ? (
              <span>
                <IoLogoLinkedin
                  // color="#f68a20"
                  onClick={() => openLink(getCompanyDetails?.companyLinkedIn)}
                />
              </span>
            ) : null}
            {getCompanyDetails?.companyFacebook ? (
              <span>
                <FaFacebook
                  // color="#f68a20"
                  onClick={() => openLink(getCompanyDetails?.companyFacebook)}
                />
              </span>
            ) : null}
            {getCompanyDetails?.companyInstagram ? (
              <span>
                <BiLogoInstagramAlt
                  // color="#f68a20"
                  onClick={() => openLink(getCompanyDetails?.companyInstagram)}
                />
              </span>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoothCompany;
