import React from "react";
import useCustomFecthQuery from "../../../Hooks/useCustomFecthQuery";
import CreateBooth from "./CreateBooth";

import CompanyComponent from "./CompanyComponent";
import BoothDetails from "./BoothDetails";

import BoothNaviagtion from "./BoothNaviagtion";

const SummaryBooth = ({
  eventName,
  eventID,
  boothSpaceId,
  boothID,
  liveBroadcast,
  userEmail,
  token,
  duration,
  boothDetails,
}) => {
  const {
    data: getCompanyDetails,
    error: errorGetCompanyDetails,
    isLoading: isLoadingGetCompanyDetails,
  } = useCustomFecthQuery(
    ["companyDetails", boothID],
    `${process.env.REACT_APP_API}company-profile/booth/${boothID}`,
    token,
    { enabled: !!boothID }
  );

  // console.log(
  //   "companyDetails......",
  //   JSON.stringify(getCompanyDetails?.data, null, 2)
  // );

  // <CreateBooth
  //   eventID={eventID}
  //   boothSpaceId={boothSpaceId}
  //   liveBroadcast={liveBroadcast}
  //   duration={duration}
  //   token={token}
  //   userEmail={userEmail}
  // />;

  return (
    <div className="set-event-rightbar-content">
      <div className="w-100 d-flex">
        <div
          className="px-4"
          style={{
            width: "100%",
            margin: "0px auto",
          }}
        >
          <BoothNaviagtion
            eventName={eventName}
            eventID={eventID}
            boothID={boothID}
          />

          {boothID ? <BoothDetails boothDetails={boothDetails} /> : null}

          {boothID ? (
            <CompanyComponent
              token={token}
              boothID={boothID}
              boothDetails={boothDetails}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SummaryBooth;
