import React, { useState } from "react";
import ButtonIcon from "../../Buttons/ButtonIcon";
import { IoMdAddCircleOutline } from "react-icons/io";
import OffCanvas from "../../OffCanvas/OffCanvas";
import CompanyForm from "./CompanyForm";

const CreateCompanyProfile = ({ token, boothID }) => {
  const [isCompanyOpen, setIsCompanyOpen] = useState(false);
  const toogleCompanyBooth = () => {
    setIsCompanyOpen(!isCompanyOpen);
  };
  return (
    <div className="mt-3 mb-5 d-flex justify-content-center">
      <div
        style={{ width: "100%", height: 200 }}
        className="border border-primary rounded-4 p-3 d-flex justify-content-center align-items-center"
      >
        <ButtonIcon
          Title="Create Company"
          BackgroundColor="#f68a20"
          ColorText="white"
          BorderColor="#f68a20"
          borderRadius={20}
          handleOnclick={toogleCompanyBooth}
          Icon={<IoMdAddCircleOutline color="white" size={20} />}
          pending={false}
        />
      </div>
      <OffCanvas
        isOffcanvasOpen={isCompanyOpen}
        title="Create Company"
        body={
          <CompanyForm
            token={token}
            boothID={boothID}
            setIsCompanyOpen={setIsCompanyOpen}
          />
        }
        toggleSaveChanges={() => setIsCompanyOpen(false)}
        directionOffCanvas="end"
        widthOffCanvas={700}
      />
    </div>
  );
};

export default CreateCompanyProfile;
