import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import useCustomFecthQuery from "../../../Hooks/useCustomFecthQuery";
// import ButtonIcon from "../../../components/Buttons/ButtonIcon";

import { Box } from "@mui/material";
import WhiteLogo from "../../../assets/images/kago-color-white.png";
import SetBoothHeader from "../../../Layout/HorizontalLayout/SetBoothHeader";
import { SidebarBooth } from "../../../components/EventSetUp/Layout/Sidebar/SidebarBooth";
import { ContentBooth } from "../../../components/EventSetUp/Layout/Content/ContentBooth";
import CustomFooter from "../../../Layout/HorizontalLayout/CustomFooter";

const Index = () => {
  const navigate = useNavigate();
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  // const userID = obj?.data?.data?._id;
  const token = obj?.data?.token;
  const userEmail = obj?.data?.data?.email;
  const params = useParams();
  const eventID = params.eventID;
  const eventName = params.eventName;
  const [selectActiveTab, setSelectActiveTab] = useState("1");

  const {
    data: getBoothDetails,
    error: errorGetBoothDetails,
    isLoading: isLoadingGetBoothDetails,
  } = useCustomFecthQuery(
    ["myBooth", userEmail],
    `${process.env.REACT_APP_API}booths/?email=${userEmail}&eventId=${eventID}`,
    token,
    { enabled: !!eventID }
  );

  const {
    data: getBoothSpace,
    error: errorGetBoothSpace,
    isLoading: isLoadingGetBoothSpace,
  } = useCustomFecthQuery(
    ["myBoothSpace", userEmail],
    `${process.env.REACT_APP_API}exhibitors/user/?email=${userEmail}`,
    token,
    { enabled: !!eventID }
  );

  // const {
  //   data: getBoothCompany,
  //   error: errorGetBoothCompany,
  //   isLoading: isLoadingBoothCompany,
  // } = useCustomFecthQuery(
  //   ["myBoothCompany", userEmail],
  //   `${process.env.REACT_APP_API}company-profile/${getBoothDetails?.data?._id}`,
  //   token,
  //   { enabled: !!getBoothDetails?.data?._id }
  // );

  if (isLoadingGetBoothDetails || isLoadingGetBoothSpace) {
    return <div>Loading...</div>;
  }
  // console.log(
  //   "getBoothDetails......",
  //   JSON.stringify(getBoothDetails?.data, null, 2)
  // );

  // const isCreateBooth =
  //   getBoothDetails?._id && getBoothDetails?.status === "ok";

  // console.log("isCreateBook..###3...", isBoothCreated);
  // if (isLoadingGetBoothDetails) {
  //   return <div>Loading...</div>;
  // }

  // console.log(
  //   "getBoothSpace......",
  //   JSON.stringify(getBoothSpace?.data, null, 2)
  // );

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        position: "relative",
      }}
    >
      <img
        src={WhiteLogo}
        style={{
          position: "fixed",
          top: 0,
          left: 35,
          width: 130,
          height: 135,
          zIndex: 999,
        }}
      />
      <SetBoothHeader eventID={eventID} userEmail={userEmail} />
      <Box sx={{ backgroundColor: "#282e3f", mt: 0, p: 4 }} />
      <div className="w-100 h-100 d-flex" style={{ position: "relative" }}>
        <div
          className="h-100"
          style={{
            width: "20%",
            position: "fixed",
            backgroundColor: "#000",
            zIndex: 100,
          }}
        >
          <SidebarBooth
            selectActiveTab={selectActiveTab}
            setSelectActiveTab={setSelectActiveTab}
          />
        </div>
        <div className="w-100 d-flex justify-content-center" style={{}}>
          <div className="" style={{ width: "20%" }}></div>
          <div className="" style={{ width: "80%" }}>
            <ContentBooth
              eventName={eventName}
              eventID={eventID}
              boothSpaceId={getBoothSpace?.data?._id}
              selectActiveTab={selectActiveTab}
              boothNumber={getBoothSpace?.data?.boothNumber}
              liveBroadcast={getBoothSpace?.data?.liveBroadcast}
              duration={getBoothSpace?.data?.duration}
              userEmail={userEmail}
              token={token}
              boothID={getBoothDetails?.data?._id}
              boothDetails={getBoothDetails?.data}
            />
          </div>
        </div>
      </div>
      <div className="w-100">
        <CustomFooter />
      </div>
    </div>
  );
};

export default Index;
