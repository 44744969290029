import React from "react";
import { Row, Col } from "reactstrap";
import useCustomFecthQuery from "../../../Hooks/useCustomFecthQuery";
import "../styles/companyProfile.scss";
import { RiGlobalFill } from "react-icons/ri";
import { MdEmail } from "react-icons/md";
import { FaPhoneSquareAlt } from "react-icons/fa";
import { IoLogoLinkedin } from "react-icons/io5";
import { FaFacebook } from "react-icons/fa6";
import { BiLogoInstagramAlt } from "react-icons/bi";

const CompanyProfile = ({ token, boothID }) => {
  const {
    data: getCompanyProfile,
    error: errorGetCompanyProfile,
    isLoading: isLoadingGetCompanyProfile,
  } = useCustomFecthQuery(
    ["companyProfile", boothID],
    `${process.env.REACT_APP_API}company-profile/booth/${boothID}`,
    token,
    { enabled: !!boothID }
  );

  const openLink = (link) => {
    const newLink = window.open(`${link}`, "_blank", "noopener,noreferrer");
    if (newLink) newLink.opener = null;
  };

  if (isLoadingGetCompanyProfile) {
    return <div>...Loading</div>;
  }
  return (
    <>
      <Row>
        <div className="company-details-booth-container">
          <div className="company-details-booth-container-header">
            <div className="company-details-booth-container-header-img">
              <img src={getCompanyProfile?.data?.companyLogo} alt="booth-img" />
            </div>
            <div className="company-details-booth-container-header-text">
              <div className="company-details-booth-container-header-text-headLine">
                {getCompanyProfile?.data?.companyName}
              </div>
              <div className="company-details-booth-container-header-text-link gap-2">
                {getCompanyProfile?.data?.companyWebsite ? (
                  <div
                    className="d-flex align-items-center gap-1 fw-bold"
                    onClick={() =>
                      openLink(getCompanyProfile?.data?.companyWebsite)
                    }
                  >
                    <RiGlobalFill size={14} />
                    <span
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      {getCompanyProfile?.data?.companyWebsite.lenght === 35
                        ? `${getCompanyProfile?.data?.companyWebsite?.substring(
                            0,
                            30
                          )}...`
                        : getCompanyProfile?.data?.companyWebsite}
                    </span>
                  </div>
                ) : null}

                {getCompanyProfile?.data?.companyEmail ? (
                  <div className="d-flex align-items-center gap-1 fw-bold">
                    <MdEmail size={16} />
                    <span>{getCompanyProfile?.data?.companyEmail}</span>
                  </div>
                ) : null}

                {getCompanyProfile?.data?.companyPhone ? (
                  <div className="d-flex align-items-center gap-1 fw-bold">
                    <FaPhoneSquareAlt size={14} />
                    <span>{getCompanyProfile?.data?.companyPhone}</span>
                  </div>
                ) : null}
              </div>
              <div className="company-details-booth-container-header-text-social gap-2">
                Follow us:
                {getCompanyProfile?.data?.companyLinkedIn ? (
                  <span>
                    <IoLogoLinkedin
                      // color="#f68a20"
                      onClick={() =>
                        openLink(getCompanyProfile?.data?.companyLinkedIn)
                      }
                    />
                  </span>
                ) : null}
                {getCompanyProfile?.data?.companyFacebook ? (
                  <span>
                    <FaFacebook
                      // color="#f68a20"
                      onClick={() =>
                        openLink(getCompanyProfile?.data?.companyFacebook)
                      }
                    />
                  </span>
                ) : null}
                {getCompanyProfile?.data?.companyInstagram ? (
                  <span>
                    <BiLogoInstagramAlt
                      // color="#f68a20"
                      onClick={() =>
                        openLink(getCompanyProfile?.data?.companyInstagram)
                      }
                    />
                  </span>
                ) : null}
              </div>
            </div>
          </div>

          <div className="w-100 mt-2">
            <p>{getCompanyProfile?.data?.companyDescription}</p>
          </div>
        </div>

        <Col md={12} className="mt-2"></Col>
      </Row>
    </>
  );
};

export default CompanyProfile;
