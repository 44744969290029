import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useCustomFecthQuery from "../../Hooks/useCustomFecthQuery";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Tabs, Tab, Box, Typography } from "@mui/material";
// import { useMediaQuery } from "@mui/material";
import LobbyHeader from "../../Layout/HorizontalLayout/LobbyHeader";
import boothbgImg from "../../assets/images/background/booth-bg.png";
import BoothLobbyNav from "../../components/BoothLobby/Exhibitor/BoothLobbyNav";
import BoothLobbyCompany from "../../components/BoothLobby/Exhibitor/CompanyProfile";
import boothOne from "../../assets/images/booths/1.png";
import boothTwo from "../../assets/images/booths/2.png";
import boothThree from "../../assets/images/booths/3.png";
import boothFour from "../../assets/images/booths/4.png";

const theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
          textTransform: "none",
          borderRadius: "5px",
          minWidth: "unset",
          "&.Mui-selected": {
            backgroundColor: "#F68A20",
            color: "#fff",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
          },
          "&:not(.Mui-selected)": {
            color: "#000",
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#000",
    },
    secondary: {
      main: "#ABABAB",
    },
  },
});

const BoothLobby = () => {
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const userEmail = obj?.data?.data?.email;
  const token = obj?.data?.token;

  const params = useParams();
  const eventName = params.eventName;
  const boothName = params.boothName;
  const boothID = params.boothID;
  const [value, setValue] = useState(0);
  // const isMobile = useMediaQuery("(max-width:600px)");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const {
    data: getBoothDetails,
    error: errorGetBoothDetails,
    isLoading: isLoadingGetBoothDetails,
  } = useCustomFecthQuery(
    ["boothDetails", boothID],
    `${process.env.REACT_APP_API}booths/${boothID}`,
    token,
    { enabled: !!boothID }
  );

  // console.log(
  //   "getBoothDetails...",
  //   JSON.stringify(getBoothDetails?.data, null, 2)
  // );
  // console.log("eventName...", eventName);
  // console.log("boothName...", boothName);
  // console.log("boothID...", boothID);

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <div
          className=""
          style={{
            height: "100vh",
            width: "100%",
          }}
        >
          <LobbyHeader />
          <BoothLobbyNav
            eventName={eventName}
            boothName={getBoothDetails?.data?.boothName}
            boothID={boothID}
          />

          <div
            style={{
              height: "100vh",
              width: "100%",
              backgroundImage: `url(${boothbgImg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div
              className="w-100"
              style={{
                backgroundImage: `url(${boothbgImg})`,
                backgroundSize: "cover",
                paddingTop: "2%",
              }}
            >
              <div className="w-100 d-flex justify-content-center">
                <div style={{ width: 400, height: "100%" }}>
                  <img
                    src={
                      getBoothDetails?.data?.boothImage === "1"
                        ? boothOne
                        : getBoothDetails?.data?.boothImage === "2"
                        ? boothTwo
                        : getBoothDetails?.data?.boothImage === "3"
                        ? boothThree
                        : getBoothDetails?.data?.boothImage === "4"
                        ? boothFour
                        : null
                    }
                    alt="booth-image"
                    className="w-100 h-100"
                  />
                </div>
              </div>

              <div className="w-100 d-flex justify-content-center">
                <Box
                  sx={{ width: "80%", marginTop: "-4%", marginBottom: "4%" }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "#ABABAB",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      textColor="primary"
                      indicatorColor="secondary"
                      aria-label="navigation tabs"
                      variant="scrollable"
                      scrollButtons="auto"
                      allowScrollButtonsMobile
                    >
                      <Tab label="Description" />
                      <Tab label="Company profile" />
                      <Tab label="Documents" />
                      <Tab label="Chats" />
                      <Tab label="Enquiry" />
                    </Tabs>
                  </Box>

                  {/* Tab content area */}
                  <Box
                    sx={{
                      padding: 2,
                      borderBottomLeftRadius: 10,
                      borderBottomRightRadius: 10,
                      background: "white",
                    }}
                  >
                    {value === 0 && (
                      <Box>
                        <Typography variant="body1" sx={{ marginTop: "16px" }}>
                          {getBoothDetails?.data?.boothDescription}
                        </Typography>
                      </Box>
                    )}
                    {value === 1 && (
                      <BoothLobbyCompany token={token} boothID={boothID} />
                    )}
                    {value === 2 && (
                      <Typography>Documents Tab Content</Typography>
                    )}
                    {value === 3 && <Typography>Chats Tab Content</Typography>}
                    {value === 4 && <Typography>Equiry Tab Content</Typography>}
                  </Box>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default BoothLobby;
