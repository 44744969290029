import React, { useState, useEffect } from "react";
import usePost from "../../../../Hooks/usePost";
import { Spinner, Row, Col } from "reactstrap";
import { PiWarningCircleFill } from "react-icons/pi";

function MerchantForm({ userData, token }) {
  const { execute, pending, data, status } = usePost();
  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const Method = "PATCH",
      url = `users/${userData?._id}`;
    const raw = JSON.stringify({
      merchantDetails: {
        merchantId: inputs?.merchantId,
        merchantKey: inputs?.merchantKey,
        signature: inputs?.signature,
      },
    });
    execute(url, raw, Method, "Merchant Details Successfully Updated", token);
  };

  // console.log("userData?._id...", userData?._id);
  console.log("userData?._id...", inputs);

  useEffect(() => {
    if (status === "success") {
      const user = data;
      if (user) {
        localStorage.clear();
        localStorage.setItem("authEventKagoUser", JSON.stringify(user));
      }
    }

    if (userData?.merchantDetails?.merchantId) {
      const { merchantId, merchantKey, signature } = userData?.merchantDetails;
      setInputs({
        merchantId,
        merchantKey,
        signature,
      });
    }
  }, [status, userData?.merchantDetails?.merchantId]);

  return (
    <div className="w-100 d-flex justify-content-center mt-3">
      <form className="form-create-event" onSubmit={handleSubmitForm}>
        <Row>
          <Col md={6}>
            <label className="event__input__form__Creation">
              Merchant ID <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              name="merchantId"
              className="mb-3 form-control fromInput w-100"
              value={inputs?.merchantId}
              onChange={handleChange}
              required
            />
          </Col>
          <Col md={6}>
            <label className="event__input__form__Creation">
              Merchant Key <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              name="merchantKey"
              className="mb-3 form-control fromInput w-100"
              value={inputs?.merchantKey}
              onChange={handleChange}
              required
            />
          </Col>
          <Col md={12}>
            <label className="event__input__form__Creation">
              Passphrase <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              name="signature"
              className="mb-3 form-control fromInput w-100"
              value={inputs?.signature}
              onChange={handleChange}
              required
            />
          </Col>
          <div className="w-100">
            <p className="d-flex d-flex gap-1">
              <span>
                <PiWarningCircleFill size={20} />
              </span>
              The information provided above must match the details on your
              PayFast account.
            </p>

            <div className="w-100 d-flex justify-content-center align-items-center">
              <div className="">
                <button className="btn btn-primary rounded-5" type="submit">
                  <div className="d-flex justify-content-center align-items-center">
                    {!pending ? <span>Save</span> : null}
                    {!pending ? null : (
                      <span>
                        <Spinner as="span" animation="border" size="sm" />{" "}
                        Loading
                      </span>
                    )}
                  </div>
                </button>
              </div>
            </div>
          </div>
        </Row>
      </form>
    </div>
  );
}

export default MerchantForm;
