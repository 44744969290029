import React, { useEffect, useState, useRef } from "react";
import { Row, Col } from "reactstrap";
import { useQueryClient } from "@tanstack/react-query";
import useCustomMutation from "../../../Hooks/useCustomMutation";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import ButtonSubmit from "../../Buttons/ButtonSubmit";
import { IoImageOutline } from "react-icons/io5";

import "./boothForm.scss";

const CompanyForm = ({ token, boothID, setIsCompanyOpen }) => {
  const queryClient = useQueryClient();
  const [companyLogo, setCompanyLogo] = useState(null);
  const [inputs, setInputs] = useState({
    companyName: "",
    companyDescription: "",
    companyAddress: "",
    companyPhone: "",
    companyEmail: "",
    companyFax: "",
    companyWebsite: "",
    companyInstagram: "",
    companyFacebook: "",
    companyLinkedIn: "",
  });
  const key = ["companyDetails", boothID];
  const mutation = useCustomMutation(key);
  const [companyLogoImg, setCompanyLogoImg] = useState(null);
  const fileInputRef = useRef(null);

  const handleImageUpload = (e) => {
    const file = e.target?.files[0];

    if (file) {
      setCompanyLogo(file);
      const reader = new FileReader();
      reader.onload = (e) => setCompanyLogoImg(e.target.result);
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const submitFunc = (e) => {
    e.preventDefault();
    const Method = "POST",
      url = `company-profile/${boothID}`,
      isJSON = true;
    var formdata = new FormData();
    formdata.append("companyLogo", companyLogo);
    Object.entries(inputs).forEach(([key, value]) => {
      if (value) formdata.append(key, value);
    });

    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Booth Successfully Set",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  useEffect(() => {
    if (mutation?.isSuccess) {
      queryClient.invalidateQueries(key);
      setIsCompanyOpen(false);
    }
  }, [mutation?.isSuccess]);

  // console.log("companyLogo..", companyLogo);
  // console.log("inputs..", JSON.stringify(inputs, null, 2));

  return (
    <div className="w-100">
      <form
        autoComplete="off"
        data-testid="form"
        enctype="multipart/form-data"
        onSubmit={submitFunc}
      >
        <div className="w-100 d-flex justify-content-center">
          Logo size: 400x250px
        </div>

        <div className="w-100 d-flex justify-content-center">
          <div style={{ width: 200, height: 90 }}>
            {companyLogoImg ? (
              <img
                src={companyLogoImg}
                alt="booth-logo"
                className="w-100 h-100 border border-primary"
                style={{ borderRadius: 5, objectFit: "contain" }}
              />
            ) : (
              <div
                className="w-100 h-100 border border-primary"
                style={{ borderRadius: 5 }}
              ></div>
            )}
          </div>
        </div>
        <div className="upload-booth-exhibibtion-logo-btn-container mb-3">
          <div className="upload-booth-exhibibtion-logo-btn">
            <button
              type="button"
              onClick={() => fileInputRef?.current?.click()}
              className="upload-booth-exhibibtion-logo-item"
            >
              <IoImageOutline size={16} color="#f68a20" />
              Upload Company logo
            </button>
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
            />
          </div>
        </div>

        <div className="w-100 mt-5 d-flex justify-content-center">
          <Row>
            <Col md={6} lg={6}>
              <label>
                <span className="text-danger">*</span>Company name
              </label>
              <input
                type="text"
                name="companyName"
                value={inputs?.companyName}
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control"
                required
              />
            </Col>
            <Col md={6} lg={6}>
              <label>
                <span className="text-danger">*</span>Company address
              </label>
              <input
                type="text"
                name="companyAddress"
                value={inputs?.companyAddress}
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control "
                required
              />
            </Col>
            <Col md={12}>
              <label>
                <span className="text-danger">*</span>Company description
              </label>
              <textarea
                name="companyDescription"
                value={inputs?.companyDescription}
                onChange={handleChange}
                placeholder="Describe here..."
                style={{ width: 500, borderRadius: 7 }}
                className="w-100 mb-3 mt-1 form-control"
                rows={5}
                required
              ></textarea>
            </Col>

            <Col md={6}>
              <label>
                <span className="text-danger">*</span>Phone number
              </label>
              <div className="phone-input-container">
                <PhoneInput
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry="ZA"
                  value={inputs.companyPhone}
                  onChange={(value) =>
                    setInputs({ ...inputs, companyPhone: value })
                  }
                  className="custom-phone-input"
                />
              </div>
            </Col>
            <Col md={6}>
              <label>
                <span className="text-danger">*</span>Company email
              </label>
              <input
                type="text"
                name="companyEmail"
                value={inputs?.companyEmail}
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control "
                required
              />
            </Col>
            <Col md={6}>
              <label>Company fax</label>
              <input
                type="text"
                name="companyFax"
                value={inputs?.companyFax}
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control "
                required
              />
            </Col>
            <Col md={6}>
              <label>Company website</label>
              <input
                type="text"
                name="companyWebsite"
                value={inputs?.companyWebsite}
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control "
                required
              />
            </Col>
            <Col md={6}>
              <label>Company instagram</label>
              <input
                type="text"
                name="companyInstagram"
                value={inputs?.companyInstagram}
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control "
                required
              />
            </Col>
            <Col md={6}>
              <label>Company facebook</label>
              <input
                type="text"
                name="companyFacebook"
                value={inputs?.companyFacebook}
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control "
                required
              />
            </Col>
            <Col md={6}>
              <label>Company linkedIn</label>
              <input
                type="text"
                name="companyLinkedIn"
                value={inputs?.companyLinkedIn}
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control "
                required
              />
            </Col>
          </Row>
        </div>
        <div className="w-100 d-flex justify-content-center my-3">
          <ButtonSubmit
            Title="Create"
            BackgroundColor="#f68a20"
            ColorText="white"
            BorderColor="#f68a20"
            borderRadius={20}
            handleOnclick={submitFunc}
            pending={mutation?.isLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default CompanyForm;
